import { useEffect, useState } from 'react';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';

const useFieldsAreChanged = (
    initialValues: Record<string, any> | undefined,
    { control, getValues }: { control: Control<any>; getValues: UseFormGetValues<any> },
) => {
    const [isDirty, setIsDirty] = useState(false);
    const watchedValues = useWatch({ control });

    useEffect(() => {
        if (!initialValues) return;

        const currentValues = getValues();
        const anyFieldChanged = Object.keys(initialValues).some(key => initialValues[key] !== currentValues[key]);
        setIsDirty(anyFieldChanged);
    }, [watchedValues, initialValues, getValues]);

    return isDirty;
};

export { useFieldsAreChanged };

import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAll } from '../../../utils';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';
import { Switch, SwitchProps } from '../switch';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormSwitchProps<T extends FieldValues> = FormFieldWithControllerProps<T, SwitchProps, 'orientation'> &
    FieldOrientationOptions<true, false, false>;

declare function FormSwitchComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLButtonElement, FormSwitchProps<T>>,
): JSX.Element;

const FormSwitch = forwardRef<HTMLButtonElement, FormSwitchProps<any>>(function FormSwitch(
    {
        name,
        control,
        errorMessage,
        hideErrorMessage = false,
        helpText,
        disabled,
        readOnly,
        required,
        checked,
        defaultChecked,
        label,
        onCheckedChange,
        orientation: orientationProps = {},
        id,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue: defaultChecked,
    });
    const internalRef = useMergeRefs(field.ref, ref);
    const orientation = {
        direction: 'horizontal' as const,
        ...orientationProps,
    };

    return (
        <Field invalid={!!error?.message} required={required} readOnly={readOnly} id={id} orientation={orientation}>
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <Switch
                    {...rest}
                    ref={internalRef}
                    name={field.name}
                    checked={field.value}
                    disabled={field.disabled}
                    onCheckedChange={callAll(field.onChange, onCheckedChange)}
                />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormSwitchComponent;

export { FormSwitch };

import { forwardRef } from 'react';

import { mapClassNamesToSlots } from '../../../style-system';
import { TVComponentWithElementProps } from '../../../style-system/models';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { checkboxStyles } from './checkbox.styles';

type CheckboxProps = TVComponentWithElementProps<'input', typeof checkboxStyles, keyof SimpleFieldOptions> &
    SimpleFieldOptions;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox({ classNames, size, ...rest }, ref) {
    const { fieldProps, rootProps } = useFieldProps(rest);
    const { base, input } = mapClassNamesToSlots(checkboxStyles, {
        size,
        disabled: fieldProps.disabled,
        classNames,
    });

    return (
        <div {...rootProps} className={base}>
            <input ref={ref} {...fieldProps} type="checkbox" className={input} />
        </div>
    );
});

export type { CheckboxProps };
export { Checkbox };

import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import type { CombineRefWithProps } from '../../../models';
import { callAllHandlers } from '../../../utils';
import { Field } from '../field';
import { TextArea, TextAreaProps } from '../textarea';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormTextAreaProps<T extends FieldValues> = FormFieldWithControllerProps<T, TextAreaProps>;

declare function FormTextAreaComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLTextAreaElement, FormTextAreaProps<T>>,
): JSX.Element;

const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps<any>>(function FormTextArea(
    {
        name,
        onChange,
        onBlur,
        control,
        hideErrorMessage,
        helpText,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        fullWidth = true,
        id,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });
    const internalRef = useMergeRefs<HTMLTextAreaElement>(field.ref, ref);

    return (
        <Field invalid={!!error?.message} required={required} readOnly={readOnly} fullWidth={fullWidth} id={id}>
            {label ? <Field.Label>{label}</Field.Label> : null}
            <TextArea
                {...rest}
                name={field.name}
                ref={internalRef}
                onChange={callAllHandlers(field.onChange, onChange)}
                onBlur={callAllHandlers(field.onBlur, onBlur)}
                value={field.value ?? ''}
                defaultValue={defaultValue}
            />
            {hideErrorMessage ? null : <Field.ErrorMessage>{error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormTextAreaComponent;

export type { FormTextAreaProps };
export { FormTextArea };

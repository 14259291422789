import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useMergeRefs } from '../../../hooks';

const useEditableTextCursorPos = (ref: React.ForwardedRef<HTMLSpanElement>) => {
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const spanRef = useRef<HTMLSpanElement | null>(null);
    const mergedRef = useMergeRefs(spanRef, ref);

    const resetCursorPosition = useCallback(() => {
        setCursorPosition(null);
    }, []);

    const saveCursorPosition = () => {
        const selection = window.getSelection();
        if (!selection?.rangeCount || !spanRef.current) return;

        const range = selection.getRangeAt(0);
        const preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(spanRef.current);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        setCursorPosition(start);
    };

    const restoreCursorPosition = () => {
        if (cursorPosition === null || !spanRef.current || !spanRef.current.firstChild) return;

        const selection = window.getSelection();

        if (!selection?.rangeCount) return;

        const range = document.createRange();
        range.setStart(spanRef.current.firstChild, cursorPosition);
        range.setEnd(spanRef.current.firstChild, cursorPosition);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
    };

    useEffect(() => {
        if (cursorPosition !== null) {
            restoreCursorPosition();
        }
    }, [cursorPosition]);

    return { saveCursorPosition, mergedRef, resetCursorPosition };
};

export { useEditableTextCursorPos };

import { forwardRef } from 'react';

import { TVStyleProps } from '../../../style-system';

import { type FieldContext, FieldContextProvider, splitFieldProps, useFieldState } from './context';
import { ErrorMessage } from './error-message';
import { fieldStyles } from './field.styles';
import { HelpText } from './help-text';
import { Label } from './label';
import { FieldOrientation } from './orientation';

type FieldProps = FieldContext & TVStyleProps<typeof fieldStyles> & { children?: React.ReactNode };

export interface FieldComponent extends React.ForwardRefExoticComponent<FieldProps> {
    ErrorMessage: typeof ErrorMessage;
    HelpText: typeof HelpText;
    Label: typeof Label;
    Orientation: typeof FieldOrientation;
}

const Field = forwardRef<HTMLDivElement, FieldProps>(function Field(
    { children, orientation = { direction: 'vertical' }, fullWidth, ...props },
    ref,
) {
    const [formControlProps, rootProps] = splitFieldProps({ ...props, orientation });
    const fieldState = useFieldState(formControlProps);

    return (
        <FieldContextProvider value={fieldState}>
            <div
                {...fieldState.getRootProps(rootProps, ref)}
                className={fieldStyles({ fullWidth, size: fieldState.size })}
            >
                {children}
            </div>
        </FieldContextProvider>
    );
}) as FieldComponent;

Field.Label = Label;
Field.HelpText = HelpText;
Field.ErrorMessage = ErrorMessage;
Field.Orientation = FieldOrientation;

export type { FieldProps };
export { Field };

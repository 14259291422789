import { forwardRef } from 'react';

import { mapClassNamesToSlots } from '../../../style-system';
import { TVComponentWithElementProps } from '../../../style-system/models';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { textareaStyles } from './textarea.styles';

type TextAreaProps = TVComponentWithElementProps<'textarea', typeof textareaStyles, keyof SimpleFieldOptions> &
    SimpleFieldOptions;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
    { value, variant = 'outline-transparent', noOutline, w: width, classNames, ...rest },
    ref,
) {
    const { size, fieldProps, rootProps } = useFieldProps<HTMLTextAreaElement>(rest);
    const { base, textarea } = mapClassNamesToSlots(textareaStyles, {
        noOutline,
        variant,
        size,
        disabled: fieldProps.disabled,
        w: width,
        classNames,
    });

    return (
        <>
            <div {...rootProps} className={base}>
                <textarea ref={ref} {...rest} {...fieldProps} value={value} className={textarea} />
            </div>
        </>
    );
});

export type { TextAreaProps };
export { TextArea };

import React, { forwardRef } from 'react';

import { callAllHandlers } from '../../../utils';
import { Text, type TextProps } from '../../text';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { editableTextStyles } from './editable-text.styles';
import { useEditableTextCursorPos } from './editable-text.utils';

type EditableTextProps = TextProps<'span'> & {
    name?: string;
    value?: string;
    onChange?: (value?: React.FormEvent<HTMLSpanElement> | string) => void;
    onBlur?: (value?: React.FocusEvent<HTMLSpanElement> | string) => void;
} & SimpleFieldOptions;

const EditableText = forwardRef<HTMLSpanElement, EditableTextProps>(function EditableText(
    { value, onChange, id, onBlur, readOnly, disabled, required, invalid, size, className, ...rest },
    ref,
) {
    const { saveCursorPosition, resetCursorPosition, mergedRef } = useEditableTextCursorPos(ref);

    const handleInput: React.FormEventHandler<HTMLSpanElement> = e => {
        saveCursorPosition();
        onChange?.(e.currentTarget.innerText ?? '');
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Enter') {
            (e.target as HTMLElement).blur();
        }
    };

    const { fieldProps, rootProps } = useFieldProps<HTMLSpanElement>({
        readOnly,
        disabled,
        required,
        invalid,
        id,
        onBlur: callAllHandlers(resetCursorPosition, onBlur),
        ...rest,
    });

    return (
        <span {...rootProps}>
            <Text
                as="span"
                size={size}
                px={1}
                py={1}
                contentEditable={fieldProps.disabled ? 'false' : 'true'}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                ref={mergedRef}
                className={editableTextStyles({ className })}
                {...fieldProps}
            >
                {value}
            </Text>
        </span>
    );
});

export type { EditableTextProps };
export { EditableText };

import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAllHandlers } from '../../../utils';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';
import { Input, type InputProps } from '../input';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormInputProps<T extends FieldValues> = FormFieldWithControllerProps<T, InputProps> &
    FieldOrientationOptions<true>;

declare function FormInputComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLInputElement, FormInputProps<T>>,
): JSX.Element;

const FormInput = forwardRef<HTMLInputElement, FormInputProps<any>>(function FormInput(
    {
        name,
        onChange,
        onBlur,
        control,
        hideErrorMessage,
        helpText,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        fullWidth = true,
        id,
        size,
        orientation,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });
    const internalRef = useMergeRefs<HTMLInputElement>(field.ref, ref);

    return (
        <Field
            size={size}
            invalid={!!error?.message}
            required={required}
            readOnly={readOnly}
            fullWidth={fullWidth}
            orientation={orientation}
            id={id}
        >
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <Input
                    {...rest}
                    name={field.name}
                    ref={internalRef}
                    onChange={callAllHandlers(field.onChange, onChange)}
                    onBlur={callAllHandlers(field.onBlur, onBlur)}
                    value={field.value ?? ''}
                    defaultValue={defaultValue}
                />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormInputComponent;

export type { FormInputProps };
export { FormInput };

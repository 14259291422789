import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAllHandlers } from '../../../utils';
import { EditableText, EditableTextProps } from '../editable-text';
import { Field } from '../field';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormEditableTextProps<T extends FieldValues> = FormFieldWithControllerProps<T, EditableTextProps>;

declare function FormEditableTextComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLSpanElement, FormEditableTextProps<T>>,
): JSX.Element;

const FormEditableText = forwardRef<HTMLSpanElement, FormEditableTextProps<any>>(function FormEditableText(
    {
        name,
        onChange,
        onBlur,
        onFocus,
        control,
        hideErrorMessage,
        helpText,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        fullWidth = true,
        id,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });
    const internalRef = useMergeRefs<HTMLSpanElement>(field.ref, ref);

    return (
        <Field invalid={!!error?.message} required={required} readOnly={readOnly} fullWidth={fullWidth} id={id}>
            {label ? <Field.Label>{label}</Field.Label> : null}
            <EditableText
                {...rest}
                name={field.name}
                ref={internalRef}
                onChange={callAllHandlers(field.onChange, onChange)}
                onBlur={callAllHandlers(field.onBlur, onBlur)}
                value={field.value ?? ''}
                defaultValue={defaultValue}
            />
            {hideErrorMessage ? null : <Field.ErrorMessage>{error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormEditableTextComponent;

export type { FormEditableTextProps };
export { FormEditableText };
